
  
  body {
    font-family: Arial, sans-serif;
  }
  
  /* Navbar container */
  .navbar {
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 1rem;
    background-color: #0069C8;
    position: relative;
    margin: '0 auto',

  }
  
  /* Brand */
  .navbar-brand {
    width: 200px;
    height: auto;
  }
  
  /* Nav Links */
  .nav-links {
    list-style: none;
    display: flex;
    align-items: center;
  }
  
  .nav-links li {
    position: relative;
    margin-left: 1.5rem;
  }
  
  .nav-links a {
    color: #ffffff;
    text-decoration: none;
    font-size: 1rem;
    padding: 0.5rem;
  }
  
  .nav-links a:hover {
    border: solid 0px 0px 0px 1px #fff;
    border-radius: 5px;
  }
  
  .hamburger {
    display: none;
    flex-direction: column;
    cursor: pointer;
  }
  
  .hamburger .bar {
    width: 25px;
    height: 3px;
    background-color: #333;
    margin: 4px 0;
    transition: 0.3s;
  }
  
  /* Dropdown Menu */
  .dropdown {
    position: absolute;
    top: 100%;
    left: 0;
    background-color: #fff;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    padding: 10px 0;
    display: flex;
    flex-direction: column;
    min-width: 180px;
    z-index: 1000;
  }
  
  .dropdown li {
    padding: 0.5rem 1rem;
    width: 100%;
    list-style: none;
  }
  
  .dropdown li a {
    color: #333;
    text-decoration: none;
    display: block;
    width: 100%;
    list-style: none;

  }
  
  .dropdown li a:hover {
    background-color: #0069C8;
    color: #fff;
  }
  .navbutton {
    padding: 12px;
    color: #fff;
    background-color: #0069C8; /* Corrected background color */
    border: 2px solid #ffffff;
    border-radius: 100px;
    cursor: pointer;
    font-size: 1rem; /* Removed single quotes */
    transition: background-color 0.3s ease; /* Removed comma */
    margin-left: 20px; /* Removed single quotes */
    
}

  /* Mobile Styles */
  @media (max-width: 768px) {
    .nav-links {
      position: fixed;
      top: 0;
      left: 0;
      width: 250px;
      height: 100%;
      flex-direction: column;
      background-color: #0069C8;
      transform: translateX(-100%);
      transition: transform 0.3s ease;
      padding-top: 60px;
      z-index: 1000;
    }
  
    .nav-links.open {
      transform: translateX(0);
    }
  
    .nav-links li {
      margin: 1rem 0;
      text-align: center;
    }
  
    .hamburger {
      display: flex;
    }
  
    /* Mobile Dropdown */
    .dropdown {
      position: relative;
      left: 0;
    }
    .dropdown {
        position: absolute;
        top: 100%;
        left: 0;
        background-color: #fff;
        box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
        border-radius: 5px;
        padding: 10px 0;
        display: flex;
        flex-direction: column;
        min-width: 180px;
        z-index: 1000;
      }
  }
  