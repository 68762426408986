h1 {
    font-family: 'Jaldi', sans-serif;
    font-weight: 700; /* Use bold variant */
  }
  p{
    color: #7a7a7a;
  }
  /* HealthCare.css */
.mobileimage{
    display: none;
}
.mobileform{
    display: none;
}
.marginleft{
    margin-left: 80px;
}
.marginright{
    margin-right: 80px;
}
.textColumn {
    margin-left: 80px; /* Remove margin for mobile */
}
/* For mobile devices (max-width: 768px) */
@media (max-width: 768px) {
    .container {
        flex-direction: column;
    }
    .cardcontainer {
        flex-direction: column;
        align-items: center;
        justify-content: center;
        
    }
    .imageColumn {
        margin-top: 20px;
        text-align: center;
        margin-bottom: 20px; /* Space between image and text */
    }
    .textColumn {
        text-align: center;
        margin-left: 0; /* Remove margin for mobile */
    }
    .mobileimage{
        display: block;
        margin: 40px;
    }
    .mobileform{
        display: block;
        margin-bottom: 60px;
        width: 80%;
    }
    .herotextColumn{

        text-align: center;
    }
    .heroimage{
        display: none;
    }
    .marginleft{
        margin: 10px;
    }
    .marginright{
        margin: 10px;
    }
    .card{
        width: 65%;
    }
}
